import type { FamilyTreeInterface } from '~/entities/family-tree'
import type { GetProperty } from '~/util/get/get-property'

export interface RegisterInterface {
  id: number
  funeral_company_id: number
  relative_id: number
  created_by_id: number
  active: boolean
  slug: string
  firstname: string
  lastname: string
  gender: string
  date_of_birth: string | null
  date_of_death: string | null
  memorial_text: string
  invitation_uuid: string
  created_at: string
  updated_at: string
  birth_location_id: number
  death_location_id: number
  background_id: number
  profile_picture_id: number | null
  funeral_card_id: number
  staff_id: number | null
  profile_picture_path: string
  background_picture_path: string
  funeral_card_path: string
  slug_path: string
  qr_id: number
  qr_code_path: string
  number_of_light_candles: GetProperty<number>
  number_of_messages: GetProperty<number>
  number_of_flowers: GetProperty<number>
  family_tree: FamilyTreeInterface | null
}

export class Register implements RegisterInterface {
  id: number
  funeral_company_id: number
  relative_id: number
  created_by_id: number
  active: boolean
  slug: string
  firstname: string
  lastname: string
  gender: string
  date_of_birth: string | null
  date_of_death: string | null
  memorial_text: string
  invitation_uuid: string
  created_at: string
  updated_at: string
  birth_location_id: number
  death_location_id: number
  background_id: number
  profile_picture_id: number | null
  funeral_card_id: number
  staff_id: number | null
  profile_picture_path: string
  background_picture_path: string
  funeral_card_path: string
  slug_path: string
  qr_id: number
  qr_code_path: string
  number_of_light_candles: GetProperty<number>
  number_of_messages: GetProperty<number>
  number_of_flowers: GetProperty<number>
  family_tree: FamilyTreeInterface | null

  constructor(copyFrom: RegisterInterface) {
    this.id = copyFrom.id
    this.funeral_company_id = copyFrom.funeral_company_id
    this.relative_id = copyFrom.relative_id
    this.created_by_id = copyFrom.created_by_id
    this.active = copyFrom.active
    this.slug = copyFrom.slug
    this.firstname = copyFrom.firstname
    this.lastname = copyFrom.lastname
    this.gender = copyFrom.gender
    this.date_of_birth = copyFrom.date_of_birth
    this.date_of_death = copyFrom.date_of_death
    this.memorial_text = copyFrom.memorial_text
    this.invitation_uuid = copyFrom.invitation_uuid
    this.created_at = copyFrom.created_at
    this.updated_at = copyFrom.updated_at
    this.birth_location_id = copyFrom.birth_location_id
    this.death_location_id = copyFrom.death_location_id
    this.background_id = copyFrom.background_id
    this.profile_picture_id = copyFrom.profile_picture_id
    this.funeral_card_id = copyFrom.funeral_card_id
    this.staff_id = copyFrom.staff_id
    this.profile_picture_path = copyFrom.profile_picture_path
    this.background_picture_path = copyFrom.background_picture_path
    this.funeral_card_path = copyFrom.funeral_card_path
    this.number_of_light_candles = copyFrom.number_of_light_candles
    this.number_of_messages = copyFrom.number_of_messages
    this.number_of_flowers = copyFrom.number_of_flowers
    this.slug_path = copyFrom.slug_path
    this.family_tree = copyFrom.family_tree
    this.qr_id = copyFrom.qr_id
    this.qr_code_path = copyFrom.qr_code_path
  }

  get fullName(): string {
    return this.getFullName()
  }

  getFullName(): string {
    return `${this.firstname} ${this.lastname}`
  }

  getImageUrl(): string {
    return this.profile_picture_path
  }

  getSlug(): string {
    return this.slug
  }

  getUrlPath(): string {
    return `/${this.slug_path}`
  }

  getAge(): string {
    if (!this.date_of_birth || !this.date_of_death) {
      return 'Onbekend'
    }

    const birthDate = new Date(this.date_of_birth)
    const deceasedDate = new Date(this.date_of_death)

    if (isNaN(birthDate.getTime()) || isNaN(deceasedDate.getTime())) {
      return 'Onbekend'
    }

    let age = deceasedDate.getFullYear() - birthDate.getFullYear()
    const monthDiff = deceasedDate.getMonth() - birthDate.getMonth()

    if (monthDiff < 0 || (monthDiff === 0 && deceasedDate.getDate() < birthDate.getDate())) {
      age--
    }

    return age.toString()
  }

  getBirthDate(): string {
    return this.date_of_birth
      ? new Intl.DateTimeFormat('nl-NL', { dateStyle: 'long' }).format(new Date(this.date_of_birth))
      : 'Onbekend'
  }

  getDeceasedDate(): string {
    return this.date_of_death
      ? new Intl.DateTimeFormat('nl-NL', { dateStyle: 'long' }).format(new Date(this.date_of_death))
      : 'Onbekend'
  }

  hasBackgroundPicturePath(): boolean {
    return !!this.background_picture_path
  }

  getBackgroundPicturePath(): string {
    return this.background_picture_path
  }

  toPOJO(): RegisterInterface {
    return {
      id: this.id,
      funeral_company_id: this.funeral_company_id,
      relative_id: this.relative_id,
      created_by_id: this.created_by_id,
      active: this.active,
      slug: this.slug,
      firstname: this.firstname,
      lastname: this.lastname,
      gender: this.gender,
      date_of_birth: this.date_of_birth,
      date_of_death: this.date_of_death,
      memorial_text: this.memorial_text,
      invitation_uuid: this.invitation_uuid,
      created_at: this.created_at,
      updated_at: this.updated_at,
      birth_location_id: this.birth_location_id,
      death_location_id: this.death_location_id,
      background_id: this.background_id,
      profile_picture_id: this.profile_picture_id,
      funeral_card_id: this.funeral_card_id,
      staff_id: this.staff_id,
      profile_picture_path: this.profile_picture_path,
      background_picture_path: this.background_picture_path,
      funeral_card_path: this.funeral_card_path,
      number_of_light_candles: this.number_of_light_candles,
      number_of_messages: this.number_of_messages,
      number_of_flowers: this.number_of_flowers,
      slug_path: this.slug_path,
      family_tree: this.family_tree,
      qr_id: this.qr_id,
      qr_code_path: this.qr_code_path
    }
  }
}
