export interface PoemCategoryInterface {
  id: number
  created_at: string
  updated_at: string
  name: string
  slug: string
  description: string
  image_id: number
  image_path: string
}

export class PoemCategory implements PoemCategoryInterface {
  id: number
  created_at: string
  updated_at: string
  name: string
  slug: string
  description: string
  image_id: number
  image_path: string

  constructor(copyFrom: PoemCategoryInterface) {
    this.id = copyFrom.id
    this.name = copyFrom.name
    this.slug = copyFrom.slug
    this.created_at = copyFrom.created_at
    this.updated_at = copyFrom.updated_at
    this.description = copyFrom.description
    this.image_id = copyFrom.image_id
    this.image_path = copyFrom.image_path
  }

  getImageUrl(): string {
    return this.image_path || ''
  }

  toPOJO(): PoemCategoryInterface {
    return {
      id: this.id,
      name: this.name,
      slug: this.slug,
      created_at: this.created_at,
      updated_at: this.updated_at,
      description: this.description,
      image_id: this.image_id,
      image_path: this.image_path
    }
  }
}
