export interface SiteSearchInterface {
  id: number
  type: 'register' | 'location'
  searchable_element: string
  name: string
  slug_path: string
}

export class SiteSearch implements SiteSearchInterface {
  id: number
  type: 'register' | 'location' = 'register'
  searchable_element: string = ''
  name: string
  slug_path: string

  constructor(copyFrom: SiteSearchInterface) {
    this.id = copyFrom.id
    this.type = copyFrom.type
    this.searchable_element = copyFrom.searchable_element
    this.name = copyFrom.name
    this.slug_path = copyFrom.slug_path
  }

  toPOJO(): SiteSearchInterface {
    return {
      id: this.id,
      type: this.type,
      searchable_element: this.searchable_element,
      name: this.name,
      slug_path: this.slug_path
    }
  }
}
