export interface PoemInterface {
  id: number
  created_at: string
  updated_at: string
  name: string
  slug: string
  content: string
  author: string
  source_url: string
  category_id: number
  category_name: string
  image_id: number
  image_path: string
}

export class Poem implements PoemInterface {
  id: number
  created_at: string
  updated_at: string
  name: string
  slug: string
  content: string
  author: string
  source_url: string
  category_id: number
  category_name: string
  image_id: number
  image_path: string

  constructor(copyFrom: PoemInterface) {
    this.id = copyFrom.id
    this.name = copyFrom.name
    this.slug = copyFrom.slug
    this.created_at = copyFrom.created_at
    this.updated_at = copyFrom.updated_at
    this.content = copyFrom.content
    this.author = copyFrom.author
    this.source_url = copyFrom.source_url
    this.category_id = copyFrom.category_id
    this.category_name = copyFrom.category_name
    this.image_id = copyFrom.image_id
    this.image_path = copyFrom.image_path
  }

  getImageUrl(): string {
    return this.image_path || ''
  }

  toPOJO(): PoemInterface {
    return {
      id: this.id,
      name: this.name,
      slug: this.slug,
      created_at: this.created_at,
      updated_at: this.updated_at,
      content: this.content,
      author: this.author,
      source_url: this.source_url,
      category_id: this.category_id,
      category_name: this.category_name,
      image_id: this.image_id,
      image_path: this.image_path
    }
  }
}
