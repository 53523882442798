import type {RouterOptions} from "@nuxt/schema";
import {useRequestHeader, useRequestURL} from '#imports';
import type {RouteRecordRaw} from "vue-router";

const rewritePrefixRoute = (route: RouteRecordRaw, prefix: string) => {
    if (route.path.startsWith(prefix)) {
        return {
            ...route,
            path: route.path.replace(prefix, ""),
        };
    }
    return route;
}

export default <RouterOptions>{
    routes: (routes) => {
        let hostname = useRequestHeader('X-Requested-Host') ?? '';
        console.info('X-Requested-Host:', hostname);
        if (hostname == null || hostname == '') {
            hostname = useRequestURL()['hostname'];
        }
        console.info('Rewriting routes for hostname:', hostname);
        const domainArray = ['memori.local', 'memori.site', 'memori.nl', 'memori.lt'];
        const rootDomain = domainArray.find(domain => hostname.endsWith(domain));
        if (!rootDomain) {
            return routes;
        }

        const subdomain = hostname.substring(0, hostname.indexOf(rootDomain) - 1);
        // rewrite /memori folder to root
        if (hostname === rootDomain && subdomain === '') {
            console.info('Rewriting /memori folder to root');
            return routes.map((route) => rewritePrefixRoute(route, '/memori'))
        }

        // rewrite /uvw folder to root
        console.info('Rewriting /uvw folder to root');
        return routes
            .map((route) => rewritePrefixRoute(route, '/uvw'))
    },
};